<template>
    <section class="features">
        <div class="content-area-features">

            <div class="feature grid">
                <img class="image image-sm" src="@/assets/img/secret/user-friendly.png"/>
                <div class="feature-content">
                    <h3 class="title"><span class="index">1</span> {{ $t('secretFeatures.title1') }}</h3>
                    <p class="paragraph">{{ $t('secretFeatures.description1') }}</p>
                </div>
                <img class="image image-md" src="@/assets/img/secret/user-friendly.png"/>
            </div>

            <div class="feature grid">
                <img class="image image-sm" src="@/assets/img/secret/free-access.png"/>
                <div class="feature-content">
                    <h3 class="title"><span class="index">2</span> {{ $t('secretFeatures.title2') }}</h3>
                    <p class="paragraph">{{ $t('secretFeatures.description2') }}</p>
                </div>
                <img class="image image-md" src="@/assets/img/secret/free-access.png"/>
            </div>

            <div class="feature grid">
                <img class="image image-sm" src="@/assets/img/secret/integrated-wallet.png"/>
                <div class="feature-content">
                    <h3 class="title"><span class="index">3</span> {{ $t('secretFeatures.title3') }}</h3>
                    <p class="paragraph">{{ $t('secretFeatures.description3') }}</p>
                </div>
                <img class="image image-md" src="@/assets/img/secret/integrated-wallet.png"/>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data: function() {
        return {};
    },
        components: {
    }
};
</script>

<style lang="scss">
.secret .features {
    background-color: #FAFCFE;
    margin-left: -15px;
    margin-right: -15px;
    padding: 120px 30px;

    .content-area-features {
        max-width: 1170px;
        margin: 0 auto;
    }

    @media (min-width: 768px) {
        padding: 120px 60px;
    }
}

.secret .feature {
    .feature-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width: 768px) {
            align-items: flex-start;
        }
    }

    .image {
        width: 100%;
    }

    .image-md {
        display: none;
    }

    .title {
        color: #56838b;
        margin-bottom: 16px;

        .index {
                color: #F1F1F2;
                font-size: 14px;
                display: inline-block;
                width: 20px;
                height: 20px;
                padding: 3px;
                text-align: center;
                border-radius: 50%;
                margin-right: 5px;
                background-color: #19d5ae;
                position: relative;
                top: -5px;
        }
    }

    .paragraph {
        color: #58595b;

        @media (min-width: 768px) {
            text-align: left;
        }
    }
}

.secret .feature.grid {
    display: grid;
    grid-gap: 60px;
    margin-bottom: 120px;

    &:nth-child(odd) {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }

    &:nth-child(even) {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }

    @media (min-width: 768px) {
        grid-gap: 120px;
        margin-bottom: 60px;

        &:nth-child(odd) {
            grid-template-columns: calc(60% - 60px) calc(40% - 60px);
            grid-template-rows: unset;

            .image-md {
                display: block;
            }

            .image-sm {
                display: none;
            }
        }

        &:nth-child(even) {
            grid-template-columns: calc(40% - 60px) calc(60% - 60px);
            grid-template-rows: unset;

            .image-md {
                display: none;
            }

            .image-sm {
                display: block;
            }
        }
    }
}
</style>
