<template>
    <div class="szg-header">
    <!-- Overlaying contents -->
    <div class="overlaying-contents">
        <div class="circles">
            <div class="circle circle-1"></div>
            <div class="circle circle-2"></div>
            <div class="circle circle-3"></div>
            <div class="circle circle-4"></div>
        </div>
        <div class="phone"></div>
    </div>
    <!-- End of overlaying contents -->

    <div class="row ml0 mr0">
        <MainNav />
        <div class="offset-md-1 col-md-10">
            <div class="content">
                <TextBlock
                    :title1="$t('secretHeader.headerTitle1')"
                    :title2="$t('secretHeader.headerTitle2')"
                    :description="$t('secretHeader.description')"
                />
                <!-- <div class="dlbtns">
                    <DownloadBtn platform="ios" class="dlbtn" />
                    <DownloadBtn platform="andriod" class="dlbtn" />
                </div> -->
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import MainNav from "@/components/MainNav.vue";
import TextBlock from "@/components/partials/TextBlock3.vue";
import DownloadBtn from "@/components/partials/DownloadBtn.vue";

export default {
    data: function() {
        return {};
    },
        components: {
        MainNav,
        TextBlock,
        DownloadBtn
    }
};
</script>

<style lang="scss">
.szg-header {
    background-image: url("../../assets/img/product-container-1-bg.png");
    background-position: center -71px;
    background-repeat: no-repeat;
    background-size: auto;

    @media (max-width: 991.98px) {
        overflow: hidden;
        background-image: url("../../assets/img/product-container-1-bg-mobile.png");
        background-position: center center;
        background-size: cover;
    }

    @media (max-width: 767.98px) {
        overflow: initial;
    }

    &:before {
        @media (min-width: 768px) and (max-width: 991.98px) {
            content: "";
            position: absolute;
            bottom: -50px;
            left: 0;
            right: 0;
            height: 470px;
            background-image: url("../../assets/img/phone-container-1-overlay-tablet.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &:after {
        @media (max-width: 1199.98px) {
            // 991.98px
            content: "";
            position: absolute;
            bottom: -1px;
            left: -2px;
            right: 0;
            height: 14.39688715953307vw;
            background-image: url("../../assets/img/product-container-1-overlay-tablet.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media (max-width: 767.98px) {
            left: 0;
            background-image: url("../../assets/img/product-container-1-overlay-mobile.png");
        }
    }

    @media (max-width: 767.98px) {
        padding-bottom: calc(66.25vw + 30px);
    }

    margin-left: -15px;
    margin-right: -15px;

    color: white;
    height: 100vh;

    position: relative;

    @media (max-width: 991.98px) {
        height: initial;
    }

    .overlaying-contents {
        pointer-events: none;

        $circles-color: #7db4e2;

        position: absolute;
        top: 38%;
        left: 50%;
        bottom: 0;
        right: 0;

        @media (max-width: 767.98px) {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                bottom: -15vw;
                left: 0;
                right: 0;
                height: 81.25vw;
                background-image: url("../../assets/img/product.svg");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }

        .phone {
            @media (max-width: 767.98px) {
                // position: absolute;
                // height: 100%;
                // width: 80vw;
                // left: 10vw;
                // bottom: -35%;
                // background-image: url('../../assets/img/szg-phone.png');
                // background-repeat: no-repeat;
                // background-position: center center;
                // background-size: contain;

                display: none;
            }
        }

        .circles {
            @media (max-width: 767.98px) {
                // position: absolute;
                // bottom: -25vw;
                // left: 0;
                // right: 0;
                // height: 100vw;

                display: none;
            }
        }

        .circle {
            position: absolute;
            border-radius: 50%;
        }

        .circle-1 {
            @media (max-width: 767.98px) {
                width: 75vw;
                height: 75vw;
                left: calc(12.5vw);
                right: 0;
                border: 2px solid $circles-color;
            }
        }

        .circle-2 {
            @media (max-width: 767.98px) {
                width: 75vw;
                height: 75vw;
                left: calc(12.5vw - 10px);
                top: -10px;
                border: 2px solid rgba($circles-color, 0.4);
            }
        }

        .circle-3 {
            @media (max-width: 767.98px) {
                width: calc(75vw + 5px);
                height: calc(75vw + 5px);
                left: calc(12.5vw - 12.5px);
                top: -5px;
                border: 1px solid rgba($circles-color, 0.2);
            }
        }

        .circle-4 {
            @media (max-width: 767.98px) {
                width: calc(75vw + 5px);
                height: calc(75vw + 5px);
                left: calc(12.5vw - 10px);
                top: -2.5px;
                border: 1px solid rgba($circles-color, 0.2);
            }
        }
    }

    .content {
        width: 580px;
        margin-top: 100px;
        text-align: left;

        @media (max-width: 991.98px) {
            margin-top: 20px;
            width: auto;
            text-align: center;
            margin-bottom: 470px;
        }

        @media (max-width: 767.98px) {
            margin-bottom: 0;
        }

        .text-block {
            width: 470px;
            font-size: 18px;
            margin-bottom: 40px;

            @media (max-width: 991.98px) {
                width: 80%;
                margin: 0 auto;
            }

            @media (max-width: 767.98px) {
                width: initial;
            }

            h3 {
                text-transform: uppercase;
                font-size: 2.6em;
                margin-bottom: 40px;
                text-shadow: 0px 0px 5px #f5fbfc;

                @media (max-width: 991.98px) {
                font-size: 50px;
                }

                @media (max-width: 767.98px) {
                width: initial;
                font-size: 42px;
                }
            }
            p {
                font-weight: 200;

                @media (max-width: 991.98px) {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 32px;
                }

                @media (max-width: 767.98px) {
                margin-bottom: 16px;
                text-align: center;
                }
            }
        }

        .dlbtns {
            display: flex;
            justify-content: flex-start;

            @media (min-width: 768px) and (max-width: 991.98px) {
                justify-content: center;
            }

            @media (max-width: 767.98px) {
                display: none;
            }

            .dlbtn {
                margin-right: 20px;

                @media (min-width: 768px) and (max-width: 991.98px) {
                font-size: 14px;
                }
            }

            .icon {
                @media (min-width: 768px) and (max-width: 991.98px) {
                width: 28px;
                height: 28px;
                margin-right: 0;
                }
            }
        }
    }
}

.cn .szg {
    .content {
            .text-block {
                width: 440px;

                @media (max-width: 991.98px) {
                    width: 80%;
                }

                @media (max-width: 767.98px) {
                    width: initial;
                }
            }

            .title {
                @media (min-width: 768px) and (max-width: 991.98px) {
                    width: 85%;
                    margin: 0 auto 40px;
                }

                @media (min-width: 1200px) {
                    font-size: 60px;
                    font-weight: 900;
                }
            }

            .dlbtns .dlbtn {
                @media (min-width: 768px) {
                    margin-right: 30px;
                }
            }
    }
}

.cn .szg-header {
    .move-right {
        right: 0;
    }
}
</style>
