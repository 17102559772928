<template>
    <section class="minor-features">
        <div class="content-area-minor-features grid">
            <div class="minor-feature">
                <h4 class="title">{{ $t('secretMinorFeatures.title1') }}</h4>
                <p class="paragraph">{{ $t('secretMinorFeatures.description11') }}</p>
                <p class="paragraph">{{ $t('secretMinorFeatures.description12') }}</p>
            </div>

            <div class="minor-feature">
                <h4 class="title">{{ $t('secretMinorFeatures.title2') }}</h4>
                <p class="paragraph">{{ $t('secretMinorFeatures.description2') }}</p>
            </div>

            <div class="minor-feature">
                <h4 class="title">{{ $t('secretMinorFeatures.title3') }}</h4>
                <ul class="list">
                    <li class="list-item">{{ $t('secretMinorFeatures.description31') }}</li>
                    <li class="list-item">{{ $t('secretMinorFeatures.description32') }}</li>
                    <li class="list-item">{{ $t('secretMinorFeatures.description33') }}</li>
                    <li class="list-item">{{ $t('secretMinorFeatures.description34') }}</li>
                    <li class="list-item">{{ $t('secretMinorFeatures.description35') }}</li>
                    <li class="list-item">{{ $t('secretMinorFeatures.description36') }}</li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data: function() {
        return {};
    },
        components: {
    }
};
</script>

<style lang="scss">
.secret .minor-features {
    padding: 0 15px;

    @media (min-width: 768px) {
        padding: 0;
    }
}

.secret .content-area-minor-features.grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-gap: 60px;

    @media (min-width: 768px) {
        grid-template-columns: calc(33.33% - 30px) calc(33.33% - 30px) calc(33.33% - 30px);
        grid-template-rows: auto;
        grid-gap: 60px;
    }
}

.secret .content-area-minor-features {
    max-width: 1170px;
    margin: 0 auto;
    padding: 120px 0;
    text-align: left;

    @media (min-width: 768px) {
        padding: 120px 60px;
    }
    
    .minor-feature {
        padding: 45px 30px;
        border-radius: 15px;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.225);

        @media (min-width: 768px) {
            border-radius: 30px;
            padding: 40px;
        }

        .title {
            margin-bottom: 16px;
            color: #56838b;
            font-size: 28px;
            font-weight: 700;
        }

        .paragraph,
        .list {
            color: #58595b;
            font-size: 14px;
            font-weight: 300;
        }

        .list {
            padding-inline-start: 20px;
        }
    }
}
</style>
