<template>
    <div class="secret page">
        <Header />
        <About />
        <Features />
        <MinorFeatures />
        <!-- <Cards ref="szgCards"/>
        <SectionFeatures/>
        <Demo ref="szgDemo"/> 
        <Download />-->
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/secret/SectionHeader.vue";
import About from "@/components/secret/About.vue";
import Features from "@/components/secret/Features.vue";
import MinorFeatures from "@/components/secret/MinorFeatures.vue";
// import Cards from "@/components/szg/SectionCards.vue";
// import SectionFeatures from "@/components/home/SectionFeatures.vue";
// import Demo from "@/components/szg/Demo.vue";
// import Download from "@/components/szg/Downloads.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "secret",
    head: {
        title: { inner: "Digital Valley" }
    },
    components: {
        Header,
        About,
        Features,
        MinorFeatures,
        // Cards,
        // SectionFeatures,
        // Demo,
        // Download,
        Footer
    },
    created() {
    // window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
    // window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        // handleScroll(){
        //   this.$refs.szgDemo.handleScroll()
        //   this.$refs.szgCards.handleScroll()
        // }
    }
};
</script>

<style lang="scss" scoped>
</style>

