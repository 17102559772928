<template>
    <section class="about-header">
        <h2 class="section-title">{{ $t('secretAbout.title') }}</h2>
        <p class="paragraph">{{ $t('secretAbout.description') }}</p>
    </section>
</template>

<script>
export default {
    data: function() {
        return {};
    },
        components: {
    }
};
</script>

<style lang="scss">
.secret .about-header {
    margin: 60px -15px 0;
    padding: 0 15px 55.3958333vw;
    position: relative;
    overflow: hidden;

    @media (min-width: 768px) {
        margin: 180px auto 0;
        padding: 0 15px 42.3958333vw;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: -15px;
        left: -50%;
        right: -50%;
        height: calc(62.3958333vw * 1.5);
        z-index: -1;
        background-image: url('../../assets/img/secret/about.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        
        @media (min-width: 768px) {
            left: 0;
            right: 0;
            height: 62.3958333vw;
        }
    }

    .section-title {
        font-size: 32px;
        font-weight: 700;
        color: #56838b;
        margin-bottom: 24px;

        @media (min-width: 768px) {
            font-size: 46px;
        }
    }

    .paragraph {
        color: #58595b;
        max-width: 768px;
        margin: 0 auto;
    }
}
</style>
